<template>
<div class="pc_bg">
    <!--导航栏-->
    <transition name="tabs_transition">
      <div v-if="$store.state.show_tabs" class="tabbar">
        <div style="margin-left:12vw;color:#FFFFFF;font-size:23px;">BaiYu的博客</div>
        <!--PC tab栏-->
        <div class="pc_tabs">
          <div style="display:flex;align-items:center;padding-right:12vw;">
            <div v-for="(item, index) in tabs" :key="index">
              <!--选中-->
              <div v-if="item.path == page_path" class="pc_every_tab " style="border:1px solid #FFFFFF;">
                <img :src="item.img_url" alt="前端博客" class="pc_tab_icon">
                <div class="pc_tab_font">{{item.name}}</div>
              </div>
              <!--未选中-->
              <div v-else  @click="goPage(item.path)" class="pc_every_tab tab_hover">
                <img :src="item.img_url" alt="前端博客" class="pc_tab_icon">
                <div class="pc_tab_font">{{item.name}}</div>
              </div>
            </div>
          </div>
        </div>
        <!--H5 tab更多-->
        <img class="h5_more" src="@/assets/icon/gengduo.png"  @click="visible=true">
      </div>
    </transition>

    <!--路由实例-->
    <router-view/>

    <!--音乐组件-->
    <BYmusic></BYmusic>

    <!--雪花组件-->
    <!-- <div style="position:fixed;top:10px;left:10px;height:80vh;left:80vw;">
      <let-it-snow v-bind="snowConf" :show="show"></let-it-snow>
    </div> -->
    

    <!--抽屉组件-->
    <BYdrawer :visible="visible">
        <slot>
            <div style="height:20px;"></div>
            <div v-for="(item, index) in tabs" :key="index">
              <div style="display:flex;justify-content:center;margin-bottom:10px;">
                <div class="h5_tabs"  @click="message()">
                  <img :src="item.img_url" style="height:20px;width:20px;margin-right:6px;">
                  <div style="color:#FFFFFF;">{{item.name}}</div>
                </div>
              </div>
            </div>
        </slot>
    </BYdrawer>
    <!--遮罩层组件-->
    <BYmask :visible="visible" @closePopup="closePopup"></BYmask>
</div>
</template>
<script>
import BYmask from "@/components/BYmask/BYmask";//遮罩层
import BYdrawer from "@/components/BYdrawer/BYdrawer";//抽屉
import BYmusic from '@/components/BYmusic/BYmusic';//音乐盒
  export default {
    components: {
      BYmask,
      BYdrawer,
      BYmusic,
    },
    data() {
      return {
        visible:false,
        tabs:[
          {name:'博客首页',path:'./home',img_url:require('@/assets/icon/shouye.png')},
          {name:'博文列表',path:'./list',img_url:require('@/assets/icon/qianduan.png')},
          {name:'留言板',path:'./message',img_url:require('@/assets/icon/liuyan.png')},
          {name:'生活随笔',path:'./daily',img_url:require('@/assets/icon/shenghuo.png')},
        ],
        page_path:1,
        // snowConf: {
        //     windPower : 1,  
        //     speed : 3,
        //     count : 3,
        //     size : 8,
        //     opacity : 1,
        //     images: [ require('@/assets/icon/xuehua.png'),
        //               require('@/assets/icon/xuehua2.png'),
        //               require('@/assets/icon/xuehua3.png'),]
        // },
        // show:false
      };
    },
	  mounted(){
      this.getPagePath();
      //雪花特效(暂不用)
      // this.show=true;
      // setTimeout( () => {
      //     this.show = false
      // }, 5000)
      // setTimeout( () => {
      //     this.show = true
      // }, 10000)     
	  },
    methods:{
      //判断tab栏页面
      getPagePath(){
        this.page_path = './' + window.location.href.split('/#/')[1];
      },
      //打开抽屉
      openDrawer(){
          this.visible=true;
      },
      //关闭抽屉
      closePopup(){
          this.visible=false;
      },
      //页面跳转
      goPage(path){
        this.$router.replace({path:path});
        this.page_path=path;
      },
      //手机端正在开发中
      message(){
        this.$message.warning('请使用电脑查看')
      }
    }
  }
</script>
<style scoped>
.pc_tabs{
  display: flex;
  justify-content: center;
}

/* 媒体查询电脑 */
@media screen and (min-width: 768px) {
    .pc_bg{
      min-height:100vh;
      background-image: url('~@/assets/img/BG.jpg');
      background-size: 100% 100%;
    }
    .h5_more{
      display: none;
    }
    .tabbar{
      position:fixed;
      left: 0;
      width:100vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-image: linear-gradient(to top, #265266, #393a55);
      height:70px;
      z-index: 10;
    }
    .pc_tab_icon{
      height:26px;
      width:26px;
    }
    .pc_tab_font{
      font-size:20px;
      color: #FFFFFF;
      margin-left:10px;
    }
    /* 导航切换动画 */
    .tabs_transition-enter-active, .tabs_transition-leave-active {
      transition: 0.3s;
    }
    .tabs_transition-enter, .tabs_transition-leave-to {
      margin-top:-7vh;
    }
    /* PC按钮特效 */
    .pc_every_tab{
      height:50px;
      margin-right:2vw;
      display:flex;
      align-items: center;
      justify-content: center;
      padding: 0 2vw;
      border-radius:10px;
      /* border:1px solid #FFFFFF; */
      cursor: pointer;
      outline: none;
      overflow: hidden;
      position: relative;
    }
    .tab_hover:after {
      content: '';
      display: block;
      position: absolute;
      top: -36px;
      left: -100px;
      background: white;
      width: 50px;
      height: 125px;
      opacity: 20%;
      transform: rotate(-45deg);
    }
    .pc_every_tab:hover:after {
      left: 120%;
      transition: all 1000ms cubic-bezier(0.3, 1, 0.2, 1);
      -webkit-transition: all 1000ms cubic-bezier(0.3, 1, 0.2, 1);
    }

}
/* 媒体查询手机  在480到760之间*/
@media screen and (max-width: 768px) {
    .pc_tabs{
      display: none;
    }
    .h5_tabs{
      height:40px;
      width:110px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #4339ce;
      border-radius:20px;
    }
    .tabbar{
      position:fixed;
      top:0;
      left: 0;
      width:100vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-image: linear-gradient(to top, #265266, #393a55);
      height:7vh;
      /* z-index: 10; */
    }
    .h5_more{
      height:5vh;
      width:5vh;
      padding-right:3vw;
    }
}


</style>