<template>
    <!--抽屉-->
    <div >
        <!--遮罩层-->
        <transition name="mask_transition">
            <div v-if="visible" class="by_mask" @click="$parent.visible=false"></div>
        </transition>
        <!--抽屉-->
        <transition name="drawer_transition">
            <div v-if="visible" class="by_drawer">
                <!-- 插槽 -->
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props:{
        visible:{
            type:Boolean,
            default:false,
        },//是否显示
    },
    data() {
        return {

        };
    },
};
</script>
<style scoped>
/* 遮罩层 */
.by_mask{
    overflow: hidden; 
    z-index: 10; 
    width: 100%; 
    height: 100vh; 
    position: fixed; 
    top: 0px; 
    left: 0px; 
    background:rgba(0, 0, 0, 0.4);
}
.mask_transition-enter-active, .mask_transition-leave-active {
  transition: 0.3s;
}
.mask_transition-enter, .mask_transition-leave-to {
  opacity:0;
}
/* 抽屉 */
.by_drawer{
    height:100vh;
    background-color: #FFFFFF;
    width: 40vw;
    position: fixed;
    z-index: 11;
    top:0;
    right: 0;
}
/* 设置动画时间 */
.drawer_transition-enter-active, .drawer_transition-leave-active {
  transition: 0.3s;
}
/* 设置动画效果 */
.drawer_transition-enter, .drawer_transition-leave-to {
  right: -40vw;
}
</style>