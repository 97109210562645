<template>
<!--音乐盒组件-->
<div>
    <div class="box_body">
        <div style="display:flex;align-items:center;">
            <div @click="menu()"  class="box_body_circle" >
                <img  src="~@/assets/musicbox/yinfu.png" :style="turn" class="circe_img">
            </div>
            <transition name="music_transition">
                <div v-show="box_menu" style="height:45px;background-color:rgb(104, 23, 180);border-top-right-radius:30px;border-bottom-right-radius:30px;margin-left:-12px;display:flex;align-items: center;border:2px solid #ffffff;">
                    <div class="meun_body" style="margin-left:12px;" @click="previousMusic()">
                        <img src="~@/assets/musicbox/shangyiqu.png" style="height:20px;">
                    </div>
                    <div class="meun_body" @click="ifPlay()">
                        <img v-if="music_play" src="~@/assets/musicbox/zanting.png" style="height:13px;" >
                        <img v-else src="~@/assets/musicbox/bofangyinyue.png" style="height:13px;" >
                    </div>
                    <div class="meun_body" style="margin-right:8px;" @click="nextMusic()">
                        <img src="~@/assets/musicbox/shangyiqu.png" style="height:20px;transform:rotate(180deg);">
                    </div>
                </div>
            </transition>
	    </div>

        <div v-show="box_menu" style="font-size:13px;color:#FFFFFF;margin-left:56px;margin-top:6px;">{{music_title}}</div>
        <!--音乐控件-->
        <audio autoplay id="music" :src="music_address" @ended="nextMusic()" ></audio>
    </div>
    <!--请求播放音乐弹窗-->
    <div v-if="music_popup" class="music_popup wow bounceInUp" data-wow-duration ="4s" data-wow-delay ="0s">
        <div style="height:100px;text-align:center;font-size:20px;line-height:100px;font-weight:800;">是否开启音乐盒?</div>
        <div style="height:100px;display:flex;align-items:center;justify-content:space-around;">
            <div style="height:40px;width:70px;background-color:#be67cf;color:#FFFFFF;line-height:40px;padding:0 16px;border-radius:10px;font-weight:800;cursor:pointer;" @click="playMusic()">立即开启</div>
            <div style="height:40px;width:70px;background-color:rgb(18, 172, 172);color:#FFFFFF;line-height:40px;padding:0 16px;border-radius:10px;font-weight:800;cursor:pointer;" @click="closeMusic()">不啦谢谢</div>
        </div>
    </div>
</div>

</template>
<script>
export default {
	name:'MusicBox',
	props: {
		//接收数据
	},
	data() {
		return {
            music_popup:true,//背景音乐弹窗
            box_menu:false,//音乐菜单默认不显示
            music_play:true,//默认为播放状态
            turn:'',//默认盒转动
            music_list:[
                {name:'Somewhere - July /',music:require('../../assets/music/somewhere.mp4')},
                {name:'写不完的温柔 - 邓紫棋 /',music:require('../../assets/music/xbwdwr.mp4')},
                {name:'Flower Dance - Dj Okawari /',music:require('../../assets/music/flowerdance.mp4')},
                {name:'风过境迁 - 吕口口 /',music:require('../../assets/music/fgjq.mp4')},
                {name:'Cold Winter - July /',music:require('../../assets/music/coldwinter.mp4')},
                {name:'没有如果 - 梁静茹 /',music:require('../../assets/music/myrg.mp4')},
                {name:'Monsters - 周深 /',music:require('../../assets/music/monsters.mp4')},


                // {name:'Cold Winter - July',music:require('../../assets/music/monsters.mp4')},
                // {name:'Stay Here Forever - jewel',music:require('../../assets/music/stayhereforever.mp3')},
                // {name:'凉城 - 任然',music:require('../../assets/music/liangcheng.mp3')},
                //
                // {name:'认真的老去 - 张希 曹方',music:require('../../assets/music/renzhendelaoqu.mp3')},
                // {name:'So;Lo - Kate Havnevik',music:require('../../assets/music/solo.mp3')},
                // {name:'岁月神偷 - 金玟岐',music:require('../../assets/music/suiyueshentou.mp3')},
                // {name:'In The End - Linkin Park',music:require('../../assets/music/intheend.mp3')},
            ],//音乐列表
            music_title:'',//当前播放音乐名称
            music_address:'',//当前播放音乐地址
            music_index:0,//当前播放音乐的索引
        };
    },
    mounted(){
        this.fontShow();
    },
    methods: {
        //点击显示/隐藏音乐盒菜单
        menu(){
            this.box_menu=!this.box_menu;
        },
        //初始化播放音乐
        playMusic(){
            this.music_play = true;
            this.music_title = this.music_list[this.music_index].name + ' ';
            this.music_address = this.music_list[this.music_index].music;
            this.turn ='';
            document.getElementById('music').play();
            this.music_popup = false;
        },
        //阻止播放音乐
        closeMusic(){
            this.music_play = false;
            document.getElementById('music').pause();
            this.turn = 'animation-play-state: paused;';
            this.music_popup = false;
        },
        //上一曲
        previousMusic(){
            //将状态变为播放
            this.music_play = true;
            this.turn = '';
            //如果是第一首,就跳到最后一首
            if(this.music_index==0){
                this.music_index = (this.music_list.length-1);
            }else{
                this.music_index = this.music_index - 1;
            }
            this.music_title = this.music_list[this.music_index].name + ' ';
            this.music_address = this.music_list[this.music_index].music;
        },
        //下一曲
        nextMusic(){
            //将状态变为播放
            this.music_play = true;
            this.turn = '';
            //如果是最后一首，就跳到第一首
            if(this.music_index==(this.music_list.length-1)){
                this.music_index=0
            }else{
                this.music_index = this.music_index + 1;
            }
            this.music_title = this.music_list[this.music_index].name + ' ';
            this.music_address = this.music_list[this.music_index].music;
        },
        //音乐暂停与播放方法
        ifPlay(){
            if(this.music_title==''){
                this.music_title = this.music_list[this.music_index].name + ' ';
                this.music_address = this.music_list[this.music_index].music;
            }
            let audio = document.getElementById('music'); 
            if(this.music_play == false){
                audio.play();
                this.music_play = true;
                this.turn = '';
            }else{
                audio.pause();
                this.music_play = false;
                this.turn = 'animation-play-state: paused;';
            }
        },
        //音乐文字跑马灯效果
        fontShow(){
        if(this.timer != null) return;
            this.timer = setInterval(() => {
                //获取到头的第一个字符
                let start = this.music_title.substring(0,1);
                //获取到后面的所有字符
                let end = this.music_title.substring(1);
                //重新拼接得到新的字符串，并赋值给this.message
                this.music_title = end + start;
            },400)
        },
  }
};
</script>
<style scoped>
.box_body{
    position: fixed;
    top:86vh;
    left:3vw;
}
.music_popup{
    height:200px;
    width:400px;
    background-color:#FFFFFF;
    position:fixed;
    top:60%;
    margin-top:-100px;
    margin-left:-200px;
    left:50%;
    border-radius:20px;
}
@-webkit-keyframes rotation {
      from {
          -webkit-transform: rotate(0deg);
      }

      to {
          -webkit-transform: rotate(360deg);
      }
  }	
.circe_img {
      height:30px;
      width:30px;
      -webkit-transform: rotate(360deg);
      animation: rotation 10s linear infinite;
      -moz-animation: rotation 10s linear infinite;
      -webkit-animation: rotation 10s linear infinite;
      -o-animation: rotation 10s linear infinite;
}
.box_body_circle{
    height:60px;
    width:60px;
    border-radius:50%;
    border:3px solid #FFFFFF;
    cursor: pointer;
    background-color:rgb(70, 20, 117);
    display:flex;
    justify-content:center;
    align-items:center;
    z-index:99;
    transition: 0.3s;
}
.box_body_circle:hover{
    background-color:rgb(178, 226, 66);
}
.meun_body{
    display:flex;
    align-items: center;
    justify-content: center;
    height:40px;
    width:40px;
    border-radius:50%;
    cursor: pointer;
    transition: 0.3s;
}
.meun_body:hover{
    background-color:rgb(66, 189, 226);
}
/* 设置动画时间 */
.music_transition-enter-active, .music_transition-leave-active {
  transition: 0.3s;
}
/* 设置动画效果 */
.music_transition-enter, .music_transition-leave-to {
  padding-left:30px;
  opacity:0;
}
</style>